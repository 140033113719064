import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { TokenExpired } from '@shared/state/base-actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit() {}

  logout() {
    this.store.dispatch(new TokenExpired());
  }
}
