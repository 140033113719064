<div class="simple-table mt-3">
  <div class="table-search-container d-flex justify-content-between" *ngIf="title || searchFn">
    <h5 *ngIf="title">{{ title }}</h5>
    <form *ngIf="searchFn">
      <div class="input-group">
        <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
        <input type="text" class="form-control" placeholder="Search..." [formControl]="filter" aria-label="Search" />
      </div>
    </form>
  </div>
  <table class="table">
    <thead class="table-search-header">
      <tr>
        <th scope="col" *ngIf="selectItems"><input type="checkbox" (click)="toggleAll()" /></th>
        <ng-container *ngFor="let mapping of columnMappings">
          <th scope="col">{{ mapping.title }}</th>
        </ng-container>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of itemsToShow(); index as i">
        <th scope="row" *ngIf="selectItems">
          <input type="checkbox" value="{{ i }}" (click)="toggle()" #itemSelector />
        </th>
        <ng-container *ngFor="let mapping of columnMappings">
          <td *ngIf="!mapping.formatFn && !mapping.template">{{ item[mapping.attribute] }}</td>
          <td *ngIf="mapping.formatFn">{{ mapping.formatFn(item[mapping.attribute]) }}</td>
          <td *ngIf="mapping.template">
            <ng-container
              [ngTemplateOutlet]="getColumnTemplate(mapping.attribute)"
              [ngTemplateOutletContext]="{ item: item[mapping.attribute] }"
            >
            </ng-container>
          </td>
        </ng-container>
        <td>
          <ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: item }"></ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex" *ngIf="paginationEnabled && filteredItems.length > pageSize">
    <ngb-pagination
      [collectionSize]="filteredItems.length"
      [ellipses]="false"
      [maxSize]="5"
      [(page)]="page"
      [pageSize]="pageSize"
      [rotate]="true"
    >
    </ngb-pagination>
    <select class="form-select ms-3" [(ngModel)]="pageSize">
      <option [value]="10">10</option>
      <option [value]="25">25</option>
      <option [value]="50">50</option>
    </select>
    <span class="mt-1 ms-1">items per page</span>
  </div>
</div>
