import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, startWith, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SimpleColumnTemplateDirective } from '@shared/simple-table/simple-column-template.directive';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements OnInit, OnDestroy {
  @Input('title')
  title!: string;

  @Input('actions')
  actionsTemplate!: TemplateRef<any>;

  @Input('columnMappings')
  columnMappings!: ColumnMapping[];

  @Input('items')
  items$!: Observable<any[]>;

  @Input('paginationEnabled')
  paginationEnabled = true;

  @Input('searchFn')
  searchFn!: Function;

  @Input('selectItems')
  selectItems: boolean = true;

  @Output() selectedItems: EventEmitter<any[]> = new EventEmitter();

  @ViewChildren('itemSelector') itemSelectors!: QueryList<ElementRef>;
  @ContentChildren(SimpleColumnTemplateDirective) columnTemplates!: QueryList<SimpleColumnTemplateDirective>;

  private selectAll = false;
  private subscriptions: Subscription[] = [];
  private allItems: any[] = [];
  public filteredItems: any[] = [];
  public filter = new FormControl('', { nonNullable: true });
  public page = 1;
  public pageSize = 10;

  ngOnInit(): void {
    this.subscriptions.push(
      this.filter.valueChanges
        .pipe(
          startWith(''),
          map((text: string) => this.search(text))
        )
        .subscribe((items) => {
          this.filteredItems = items;
        })
    );

    this.subscriptions.push(
      this.items$.subscribe((items) => {
        this.allItems = items;
        this.filteredItems = items;
        this.filter.setValue('');
      })
    );
  }

  itemsToShow(): any[] {
    if (this.paginationEnabled) {
      return this.filteredItems.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    }
    return this.filteredItems;
  }

  search(text: string): any[] {
    if (this.searchFn) {
      const term = text.toLowerCase();

      return this.allItems.filter((item) => {
        return this.searchFn(term, item);
      });
    }

    return this.allItems;
  }

  toggle() {
    let items: any[] = [];
    this.itemSelectors.forEach((selector) => {
      if (selector.nativeElement.checked) {
        items.push(this.filteredItems[selector.nativeElement.value]);
      }
    });
    this.selectedItems.emit(items);
  }

  toggleAll() {
    this.selectAll = !this.selectAll;
    this.itemSelectors.forEach((selector) => {
      selector.nativeElement.checked = this.selectAll;
    });
    this.toggle();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getColumnTemplate(attribute: string): TemplateRef<any> | null {
    if (this.columnTemplates) {
      const templates = this.columnTemplates.toArray();
      const template = templates.find((template) => template.attribute === attribute)?.template;

      if (template) {
        return template;
      }
    }
    return null;
  }
}

export interface ColumnMapping {
  title: string;
  attribute: string;
  formatFn?: Function;
  template?: boolean;
}
