import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<number> {
  fromModel(value: number | null): NgbDateStruct | null {
    if (value) {
      const date = DateTime.fromSeconds(value);
      return {
        day: parseInt(`${date.day}`, 10),
        month: parseInt(`${date.month}`, 10),
        year: parseInt(`${date.year}`, 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): number | null {
    if (date) {
      const toParse = `${this.leftFillNum(date.year, 2)}-${this.leftFillNum(date.month, 2)}-${this.leftFillNum(
        date.day,
        2
      )}`;
      const dateTime = DateTime.fromISO(toParse);
      return dateTime.toSeconds();
    }

    return null;
  }

  leftFillNum(num: number, targetLength: number) {
    return num.toString().padStart(targetLength, '0');
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}
